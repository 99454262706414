define("ember-table/-private/utils/observer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.removeObserver = _exports.observer = _exports.addObserver = void 0;
  const USE_ASYNC_OBSERVERS = true;
  function asyncObserver() {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    let fn = args.pop();
    let dependentKeys = args;
    let sync = false;
    return Ember.observer({
      dependentKeys,
      fn,
      sync
    });
  }
  function asyncAddObserver() {
    let obj, path, target, method;
    let sync = false;
    obj = arguments.length <= 0 ? undefined : arguments[0];
    path = arguments.length <= 1 ? undefined : arguments[1];
    (false && !(arguments.length === 3 || arguments.length === 4) && Ember.assert(`Expected 3 or 4 args for addObserver, got ${arguments.length}`, arguments.length === 3 || arguments.length === 4));
    if (arguments.length === 3) {
      target = null;
      method = arguments.length <= 2 ? undefined : arguments[2];
    } else if (arguments.length === 4) {
      target = arguments.length <= 2 ? undefined : arguments[2];
      method = arguments.length <= 3 ? undefined : arguments[3];
    }
    return Ember.addObserver(obj, path, target, method, sync);
  }
  function asyncRemoveObserver() {
    let obj, path, target, method;
    let sync = false;
    obj = arguments.length <= 0 ? undefined : arguments[0];
    path = arguments.length <= 1 ? undefined : arguments[1];
    (false && !(arguments.length === 3 || arguments.length === 4) && Ember.assert(`Expected 3 or 4 args for addObserver, got ${arguments.length}`, arguments.length === 3 || arguments.length === 4));
    if (arguments.length === 3) {
      target = null;
      method = arguments.length <= 2 ? undefined : arguments[2];
    } else {
      target = arguments.length <= 2 ? undefined : arguments[2];
      method = arguments.length <= 3 ? undefined : arguments[3];
    }
    return Ember.removeObserver(obj, path, target, method, sync);
  }
  const observer = USE_ASYNC_OBSERVERS ? asyncObserver : Ember.observer;
  _exports.observer = observer;
  const addObserver = USE_ASYNC_OBSERVERS ? asyncAddObserver : Ember.addObserver;
  _exports.addObserver = addObserver;
  const removeObserver = Ember.removeObserver ? asyncRemoveObserver : Ember.removeObserver;
  _exports.removeObserver = removeObserver;
});