define("ember-date-components/components/time-picker-input", ["exports", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@glimmer/component"], function (_exports, _applyDecoratedDescriptor2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{! template-lint-disable no-down-event-binding }}
  <input
    value={{@value}}
    class='time-picker__input'
    type='text'
    tabindex='-1'
    ...attributes
    {{on 'keydown' this.onKeyDown}}
    {{on 'input' this.onInput}}
  />
  */
  {
    "id": "kUHoYlRd",
    "block": "{\"symbols\":[\"@value\",\"&attrs\"],\"statements\":[[11,\"input\"],[16,2,[32,1]],[24,0,\"time-picker__input\"],[24,\"tabindex\",\"-1\"],[17,2],[24,4,\"text\"],[4,[38,0],[\"keydown\",[32,0,[\"onKeyDown\"]]],null],[4,[38,0],[\"input\",[32,0,[\"onInput\"]]],null],[12],[13]],\"hasEval\":false,\"upvars\":[\"on\"]}",
    "moduleName": "ember-date-components/components/time-picker-input.hbs"
  });
  /**
   * This is an extended {{input}} to send appropriate events for arrowUp/arrowDown.
   * It is also slightly changed to send an input-changed event when a key that is neither arrowUp/arrowDown, enter or escape
   * is pressed.
   */
  let TimePickerInput = (_dec = Ember._action, _dec2 = Ember._action, (_class = class TimePickerInput extends _component.default {
    onKeyDown(event) {
      // Tab doesn't trigger keyUp, so we need to capture it in keyDown
      switch (event.key) {
        case 'Enter':
          return this._enter(event);
        case 'Escape':
          return this._escape(event);
        case 'ArrowUp':
          return this._arrowUp(event);
        case 'ArrowDown':
          return this._arrowDown(event);
        case 'Tab':
          return this._tab(event);
      }
    }
    onInput(event) {
      let {
        value
      } = event.target;
      this.args.onInput(value);
    }
    _tab(event) {
      this.args.onTab(event);
    }
    _arrowUp(event) {
      this.args.onArrowUp(event);
    }
    _arrowDown(event) {
      this.args.onArrowDown(event);
    }
    _escape(event) {
      this.args.onEscape(event);
    }
    _enter(event) {
      this.args.onEnter(event);
    }
  }, ((0, _applyDecoratedDescriptor2.default)(_class.prototype, "onKeyDown", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "onKeyDown"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onInput", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "onInput"), _class.prototype)), _class));
  _exports.default = TimePickerInput;
  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, TimePickerInput);
});