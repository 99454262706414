define("ember-cli-head/components/head-layout", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#in-element this.headElement insertBefore=null}}
    {{! template-lint-disable no-forbidden-elements }}
    <meta name="ember-cli-head-start" content=""><HeadContent /><meta name="ember-cli-head-end" content="">
  {{/in-element}}
  
  */
  {
    "id": "YeRneOWa",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,0],[[32,0,[\"headElement\"]]],[[\"guid\",\"insertBefore\"],[\"%cursor:0%\",null]],[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"meta\"],[14,3,\"ember-cli-head-start\"],[14,\"content\",\"\"],[12],[13],[8,\"head-content\",[],[[],[]],null],[10,\"meta\"],[14,3,\"ember-cli-head-end\"],[14,\"content\",\"\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"in-element\"]}",
    "moduleName": "ember-cli-head/components/head-layout.hbs"
  });
  let HeadLayout = (_dec = Ember.inject.service('-document'), (_class = class HeadLayout extends _component.default {
    /**
     * If true, this will tear down any existing head on init of this component.
     * This is useful if there is a head built with fastboot - it will then be torn down when this is initialized in the browser.
     * If you do not want this behavior, you can set this to false.
     * @public
     */

    /**
     * The element to render into. Defaults to <head> in `init`, overridable for our own tests only.
     * @private
     */

    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "document", _descriptor, this);
      (0, _defineProperty2.default)(this, "shouldTearDownOnInit", true);
      (0, _defineProperty2.default)(this, "headElement", this.args.headElement || this.document.head);
      if (this.shouldTearDownOnInit) {
        this._tearDownHead();
      }
    }

    /**
     * Tear down any previous head, if there was one.
     * @private
     */
    _tearDownHead() {
      if (this._isFastboot()) {
        return;
      }

      // clear fast booted head (if any)
      let document = this.document;
      let startMeta = document.querySelector('meta[name="ember-cli-head-start"]');
      let endMeta = document.querySelector('meta[name="ember-cli-head-end"]');
      if (startMeta && endMeta) {
        let el = startMeta.nextSibling;
        while (el && el !== endMeta) {
          document.head.removeChild(el);
          el = startMeta.nextSibling;
        }
        document.head.removeChild(startMeta);
        document.head.removeChild(endMeta);
      }
    }
    _isFastboot() {
      return typeof FastBoot !== 'undefined';
    }
  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "document", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = HeadLayout;
  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, HeadLayout);
});