define("ember-modal-dialog/components/modal-dialog", ["exports", "ember-modal-dialog/templates/components/modal-dialog"], function (_exports, _modalDialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const VALID_OVERLAY_POSITIONS = ['parent', 'sibling'];
  var _default = Ember.Component.extend({
    tagName: '',
    layout: _modalDialog.default,
    modalService: Ember.inject.service('modal-dialog'),
    destinationElementId: null,
    init() {
      this._super(...arguments);
      if (!this.get('destinationElementId')) {
        this.set('destinationElementId', this.get('modalService.destinationElementId'));
      }
    },
    modalDialogComponentName: Ember.computed('renderInPlace', 'tetherTarget', 'animatable', 'hasLiquidWormhole', 'hasLiquidTether', function () {
      let tetherTarget = this.get('tetherTarget');
      let hasLiquidTether = this.get('hasLiquidTether');
      let hasLiquidWormhole = this.get('hasLiquidWormhole');
      let animatable = this.get('animatable');
      if (this.get('renderInPlace')) {
        return 'ember-modal-dialog/-in-place-dialog';
      } else if (tetherTarget && hasLiquidTether && hasLiquidWormhole && animatable === true) {
        return 'ember-modal-dialog/-liquid-tether-dialog';
      } else if (tetherTarget) {
        this.ensureEmberTetherPresent();
        return 'ember-modal-dialog/-tether-dialog';
      } else if (hasLiquidWormhole && animatable === true) {
        return 'ember-modal-dialog/-liquid-dialog';
      }
      return 'ember-modal-dialog/-basic-dialog';
    }),
    animatable: null,
    hasLiquidWormhole: Ember.computed.readOnly('modalService.hasLiquidWormhole'),
    hasLiquidTether: Ember.computed.readOnly('modalService.hasLiquidTether'),
    didReceiveAttrs() {
      this._super(...arguments);
      if (false /* DEBUG */) {
        this.validateProps();
      }
    },
    validateProps() {
      let overlayPosition = this.get('overlayPosition');
      if (VALID_OVERLAY_POSITIONS.indexOf(overlayPosition) === -1) {
        (false && Ember.warn(`overlayPosition value '${overlayPosition}' is not valid (valid values [${VALID_OVERLAY_POSITIONS.join(', ')}])`, false, {
          id: 'ember-modal-dialog.validate-overlay-position'
        }));
      }
    },
    // onClose - set this from templates

    /* eslint-disable ember/avoid-leaking-state-in-ember-objects */

    // containerClass - set this from templates
    containerClassNames: ['ember-modal-dialog'],
    // set this in a subclass definition

    // overlayClass - set this from templates
    overlayClassNames: ['ember-modal-overlay'],
    // set this in a subclass definition

    // wrapperClass - set this from templates
    wrapperClassNames: ['ember-modal-wrapper'],
    // set this in a subclass definition

    concatenatedProperties: ['containerClassNames', 'overlayClassNames', 'wrapperClassNames'],
    /* eslint-enable ember/avoid-leaking-state-in-ember-objects */

    hasOverlay: true,
    translucentOverlay: false,
    overlayPosition: 'parent',
    // `parent` or `sibling`
    clickOutsideToClose: false,
    renderInPlace: false,
    tetherTarget: null,
    stack: Ember.computed.oneWay('elementId'),
    // pass a `stack` string to set a "stack" to be passed to liquid-wormhole / liquid-tether
    value: 0,
    // pass a `value` to set a "value" to be passed to liquid-wormhole / liquid-tether

    targetAttachment: 'middle center',
    tetherClassPrefix: null,
    attachmentClass: Ember.computed('attachment', function () {
      let attachment = this.get('attachment');
      if (Ember.isEmpty(attachment)) {
        return;
      }
      return attachment.split(' ').map(attachmentPart => {
        return `emd-attachment-${Ember.String.dasherize(attachmentPart)}`;
      }).join(' ');
    }),
    targetAttachmentClass: Ember.computed('targetAttachment', function () {
      let targetAttachment = this.get('targetAttachment') || '';
      // Convert tether-styled values like 'middle right' to 'right'
      targetAttachment = targetAttachment.split(' ').slice(-1)[0];
      return `ember-modal-dialog-target-attachment-${Ember.String.dasherize(targetAttachment)} emd-target-attachment-${Ember.String.dasherize(targetAttachment)}`;
    }),
    ensureEmberTetherPresent() {
      if (!this.get('modalService.hasEmberTether')) {
        throw new Error('Please install ember-tether in order to pass a tetherTarget to modal-dialog');
      }
    },
    actions: {
      onClose() {
        const onClose = this.get('onClose');
        // we shouldn't warn if the callback is not provided at all
        if (Ember.isNone(onClose)) {
          return;
        }
        (false && !(Ember.typeOf(onClose) === 'function') && Ember.assert('onClose handler must be a function', Ember.typeOf(onClose) === 'function'));
        onClose();
      },
      onClickOverlay(e) {
        e.preventDefault();
        const onClickOverlay = this.get('onClickOverlay');
        // we shouldn't warn if the callback is not provided at all
        if (Ember.isNone(onClickOverlay)) {
          this.send('onClose');
          return;
        }
        (false && !(Ember.typeOf(onClickOverlay) === 'function') && Ember.assert('onClickOverlay handler must be a function', Ember.typeOf(onClickOverlay) === 'function'));
        onClickOverlay();
      }
    }
  });
  _exports.default = _default;
});