define("ember-file-upload/helpers/file-queue", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  /**
    `file-queue` helper is one of the core primitives of ember-file-upload.
    It has three different flavors of invocation. The first is
    a bare call, which will return the master queue:
  
    ```hbs
    {{#with (file-queue) as |queue|}}
      {{queue.progress}}%
    {{/with}}
    ```
  
    If called with a `name` property, it will return the queue
    of that name:
  
    ```hbs
    {{#with (file-queue name="photos") as |queue|}}
      {{queue.progress}}%
    {{/with}}
    ```
  
    @class FileQueue
    @param {string} [name] The name of the queue to get information on
    @return {Queue} A collection of all queues, or a specific queue.
   */
  let FileQueueHelper = (_dec = Ember.inject.service('file-queue'), (_class = class FileQueueHelper extends Ember.Helper {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "fileQueue", _descriptor, this);
    }
    compute(_, hash) {
      let queues = this.fileQueue;
      let queueName = hash['name'];
      if (queueName) {
        let queue = queues.find(queueName) || queues.create(queueName);
        return queue;
      }
      return queues;
    }
  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "fileQueue", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = FileQueueHelper;
});