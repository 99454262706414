define("ember-table/-private/utils/default-to", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = defaultTo;
  let VALUES = new WeakMap();
  function valuesFor(obj) {
    if (!VALUES.has(obj)) {
      VALUES.set(obj, Object.create(null));
    }
    return VALUES.get(obj);
  }
  function defaultTo(defaultValue) {
    return Ember.computed({
      get(key) {
        let values = valuesFor(this);
        if (!(key in values)) {
          values[key] = typeof defaultValue === 'function' ? defaultValue() : defaultValue;
        }
        return values[key];
      },
      set(key, value) {
        let values = valuesFor(this);
        if (value === undefined) {
          values[key] = typeof defaultValue === 'function' ? defaultValue() : defaultValue;
        } else {
          values[key] = value;
        }
        return values[key];
      }
    });
  }
});