define("ember-file-upload/mixins/with-files", ["exports", "ember-file-upload/computed/sum-by"], function (_exports, _sumBy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    /**
      Flushes the `files` property if they have settled. This
      will only flush files when all files have arrived at a terminus
      of their state chart.
       ```
          .------.     .---------.     .--------.
      o--| queued |-->| uploading |-->| uploaded |
          `------`     `---------`     `--------`
             ^              |    .-------.
             |              |`->| aborted |
             |              |    `-------`
             |  .------.    |    .---------.
             `-| failed |<-` `->| timed_out |-.
             |  `------`         `---------`  |
             `-------------------------------`
      ```
       Files *may* be requeued by the user in the `failed` or `timed_out`
      states.
       @method flush
     */
    flush() {
      let fileQueue = this.fileQueue;
      if (fileQueue) {
        fileQueue.flush();
      }
      let files = this.files;
      if (files.length === 0) {
        return;
      }
      let allFilesHaveSettled = files.every(file => {
        return ['uploaded', 'aborted'].includes(file.state);
      });
      if (allFilesHaveSettled) {
        this.files.forEach(file => Ember.set(file, 'queue', null));
        Ember.set(this, 'files', Ember.A());
      }
    },
    /**
      The total size of all files currently being uploaded in bytes.
       @computed size
      @type Number
      @default 0
      @readonly
     */
    size: (0, _sumBy.default)('files', 'size'),
    /**
      The number of bytes that have been uploaded to the server.
       @computed loaded
      @type Number
      @default 0
      @readonly
     */
    loaded: (0, _sumBy.default)('files', 'loaded'),
    /**
      The current progress of all uploads, as a percentage in the
      range of 0 to 100.
       @computed progress
      @type Number
      @default 0
      @readonly
     */
    progress: Ember.computed('size', 'loaded', {
      get() {
        let percent = this.loaded / this.size || 0;
        return Math.floor(percent * 100);
      }
    })
  });
  _exports.default = _default;
});