define("ember-date-components/helpers/date-picker-day-classes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.datePickerDayClasses = datePickerDayClasses;
  _exports.default = void 0;
  function datePickerDayClasses(_ref, _ref2) {
    let [baseClass] = _ref;
    let {
      isToday = false,
      isSelected = false,
      isDisabled = false,
      isInRange = false
    } = _ref2;
    let isTodayClass = isToday ? ` ${baseClass}--today` : '';
    let isSelectedClass = isSelected ? ` ${baseClass}--selected` : '';
    let isDisabledClass = isDisabled ? ` ${baseClass}--disabled` : '';
    let isInRangeClass = isInRange ? ` ${baseClass}--in-range` : '';
    return `${baseClass}${isTodayClass}${isSelectedClass}${isDisabledClass}${isInRangeClass}`;
  }
  var _default = Ember.Helper.helper(datePickerDayClasses);
  _exports.default = _default;
});