define("ember-rollbar-client/services/rollbar", ["exports", "lodash.merge", "rollbar"], function (_exports, _lodash, _rollbar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    enabled: Ember.computed({
      get() {
        return this.get('config.enabled');
      },
      set(key, value) {
        this.get('notifier').configure({
          enabled: value
        });
        return value;
      }
    }),
    currentUser: Ember.computed({
      set(key, value) {
        this.get('notifier').configure({
          payload: {
            person: value
          }
        });
        return value;
      }
    }),
    notifier: Ember.computed(function () {
      return this.rollbarClient();
    }).readOnly(),
    config: Ember.computed(function () {
      return Ember.getOwner(this).resolveRegistration('config:environment').emberRollbarClient;
    }).readOnly(),
    rollbarClient() {
      let customConfig = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      let config = (0, _lodash.default)({}, this.get('config'), customConfig);
      return new _rollbar.default(config);
    },
    // Notifications

    critical() {
      return this.get('notifier').critical(...arguments);
    },
    error() {
      return this.get('notifier').error(...arguments);
    },
    warning() {
      return this.get('notifier').warning(...arguments);
    },
    info() {
      return this.get('notifier').info(...arguments);
    },
    debug() {
      return this.get('notifier').debug(...arguments);
    }
  });
  _exports.default = _default;
});