define("ember-date-components/helpers/is-equal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.isEqual = isEqual;
  function isEqual(_ref) {
    let [a, b] = _ref;
    return a === b;
  }
  var _default = Ember.Helper.helper(isEqual);
  _exports.default = _default;
});