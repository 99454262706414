define("ember-date-components/components/date-picker-inline", ["exports", "ember-date-components/components/date-picker"], function (_exports, _datePicker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{! template-lint-disable no-inline-styles }}
  <div
    class='date-picker date-picker--inline {{
      if this.options ' date-picker--options'
    }}'
    ...attributes
  >
    <div class='date-picker__calendar'>
      <DatePickerNavigation
        @currentMonth={{this.currentMonth}}
        @minDate={{@minDate}}
        @maxDate={{@maxDate}}
        @disableMonthPicker={{@disableMonthPicker}}
        @disableYearPicker={{@disableYearPicker}}
        @availableYearOffset={{@availableYearOffset}}
        @updateMonth={{this.gotoMonth}}
      />
  
      <DatePickerMonth
        @disabledDates={{@disabledDates}}
        @month={{this.currentMonth}}
        @selectedDates={{this.selectedDates}}
        @selectDate={{this.selectDate}}
        @minDate={{@minDate}}
        @maxDate={{@maxDate}}
        @startWeekOnSunday={{this.startWeekOnSunday}}
      />
    </div>
  
    {{#if this.options}}
      <div class='date-picker__options'>
        {{#each this.availableOptions as |opt|}}
          <button
            type='button'
            class='date-picker__options__button {{opt.classes}}'
            {{on 'click' (fn opt.action opt.actionValue)}}
          >
            {{opt.label}}
          </button>
        {{/each}}
      </div>
    {{/if}}
  </div>
  */
  {
    "id": "0otQhfJD",
    "block": "{\"symbols\":[\"opt\",\"&attrs\",\"@minDate\",\"@maxDate\",\"@disableMonthPicker\",\"@disableYearPicker\",\"@availableYearOffset\",\"@disabledDates\"],\"statements\":[[11,\"div\"],[16,0,[31,[\"date-picker date-picker--inline \",[30,[36,4],[[32,0,[\"options\"]],\" date-picker--options\"],null]]]],[17,2],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"date-picker__calendar\"],[12],[2,\"\\n    \"],[8,\"date-picker-navigation\",[],[[\"@currentMonth\",\"@minDate\",\"@maxDate\",\"@disableMonthPicker\",\"@disableYearPicker\",\"@availableYearOffset\",\"@updateMonth\"],[[32,0,[\"currentMonth\"]],[32,3],[32,4],[32,5],[32,6],[32,7],[32,0,[\"gotoMonth\"]]]],null],[2,\"\\n\\n    \"],[8,\"date-picker-month\",[],[[\"@disabledDates\",\"@month\",\"@selectedDates\",\"@selectDate\",\"@minDate\",\"@maxDate\",\"@startWeekOnSunday\"],[[32,8],[32,0,[\"currentMonth\"]],[32,0,[\"selectedDates\"]],[32,0,[\"selectDate\"]],[32,3],[32,4],[32,0,[\"startWeekOnSunday\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[6,[37,4],[[32,0,[\"options\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"date-picker__options\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,0,[\"availableOptions\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[11,\"button\"],[16,0,[31,[\"date-picker__options__button \",[32,1,[\"classes\"]]]]],[24,4,\"button\"],[4,[38,1],[\"click\",[30,[36,0],[[32,1,[\"action\"]],[32,1,[\"actionValue\"]]],null]],null],[12],[2,\"\\n          \"],[1,[32,1,[\"label\"]]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\",\"-track-array\",\"each\",\"if\"]}",
    "moduleName": "ember-date-components/components/date-picker-inline.hbs"
  });
  class DatePickerInline extends _datePicker.default {}
  _exports.default = DatePickerInline;
  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, DatePickerInline);
});