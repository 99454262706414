define("ember-date-components/components/date-picker-navigation", ["exports", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@glimmer/component"], function (_exports, _applyDecoratedDescriptor2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class='date-picker__header'>
    <button
      type='button'
      class='date-picker__header__button date-picker__header__button--previous'
      data-test-date-picker-goto-previous-month
      {{on 'click' this.gotoPreviousMonth}}
    >
      &lt;
    </button>
  
    <button
      type='button'
      class='date-picker__header__button date-picker__header__button--next'
      data-test-date-picker-goto-next-month
      {{on 'click' this.gotoNextMonth}}
    >
      &gt;
    </button>
  
    <div
      class='date-picker__header__title'
      data-test-date-picker-month={{moment-format @currentMonth 'MM'}}
      data-test-date-picker-year={{moment-format @currentMonth 'YYYY'}}
    >
      <DatePickerMonthYearSelect
        @minDate={{@minDate}}
        @maxDate={{@maxDate}}
        @currentMonth={{@currentMonth}}
        @disableMonthPicker={{@disableMonthPicker}}
        @disableYearPicker={{@disableYearPicker}}
        @availableYearOffset={{this.availableYearOffset}}
        @gotoMonth={{this.gotoMonth}}
      />
    </div>
  </div>
  */
  {
    "id": "QrSRVFDm",
    "block": "{\"symbols\":[\"@currentMonth\",\"@minDate\",\"@maxDate\",\"@disableMonthPicker\",\"@disableYearPicker\"],\"statements\":[[10,\"div\"],[14,0,\"date-picker__header\"],[12],[2,\"\\n  \"],[11,\"button\"],[24,0,\"date-picker__header__button date-picker__header__button--previous\"],[24,\"data-test-date-picker-goto-previous-month\",\"\"],[24,4,\"button\"],[4,[38,0],[\"click\",[32,0,[\"gotoPreviousMonth\"]]],null],[12],[2,\"\\n    <\\n  \"],[13],[2,\"\\n\\n  \"],[11,\"button\"],[24,0,\"date-picker__header__button date-picker__header__button--next\"],[24,\"data-test-date-picker-goto-next-month\",\"\"],[24,4,\"button\"],[4,[38,0],[\"click\",[32,0,[\"gotoNextMonth\"]]],null],[12],[2,\"\\n    >\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"date-picker__header__title\"],[15,\"data-test-date-picker-month\",[30,[36,1],[[32,1],\"MM\"],null]],[15,\"data-test-date-picker-year\",[30,[36,1],[[32,1],\"YYYY\"],null]],[12],[2,\"\\n    \"],[8,\"date-picker-month-year-select\",[],[[\"@minDate\",\"@maxDate\",\"@currentMonth\",\"@disableMonthPicker\",\"@disableYearPicker\",\"@availableYearOffset\",\"@gotoMonth\"],[[32,2],[32,3],[32,1],[32,4],[32,5],[32,0,[\"availableYearOffset\"]],[32,0,[\"gotoMonth\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"on\",\"moment-format\"]}",
    "moduleName": "ember-date-components/components/date-picker-navigation.hbs"
  });
  /**
   * Arguments:
   * - currentMonth
   * - minDate
   * - maxDate
   * - disableMonthPicker
   * - disableYearPicker
   * - availableYearOffset
   * - updateMonth
   */
  let DatePickerNavigation = (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, (_class = class DatePickerNavigation extends _component.default {
    get availableYearOffset() {
      return this.args.availableYearOffset || 10;
    }
    gotoNextMonth() {
      let month = this.args.currentMonth;
      this._sendAction(month.clone().add(1, 'month'));
    }
    gotoPreviousMonth() {
      let month = this.args.currentMonth;
      this._sendAction(month.clone().subtract(1, 'month'));
    }
    gotoMonth(month) {
      this._sendAction(month.clone().startOf('month'));
    }
    _sendAction(newMonth) {
      this.args.updateMonth(newMonth);
    }
  }, ((0, _applyDecoratedDescriptor2.default)(_class.prototype, "gotoNextMonth", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "gotoNextMonth"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "gotoPreviousMonth", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "gotoPreviousMonth"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "gotoMonth", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "gotoMonth"), _class.prototype)), _class));
  _exports.default = DatePickerNavigation;
  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, DatePickerNavigation);
});