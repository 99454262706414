define("ember-date-components/helpers/is-equal-year", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.isEqualYear = isEqualYear;
  function isEqualYear(_ref) {
    let [y1, y2] = _ref;
    if (!_moment.default.isMoment(y1) || !_moment.default.isMoment(y2)) {
      return false;
    }
    return y1.format('YYYY') === y2.format('YYYY');
  }
  var _default = Ember.Helper.helper(isEqualYear);
  _exports.default = _default;
});