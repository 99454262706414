define("@html-next/vertical-collection/components/vertical-collection/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    {{#each this.virtualComponents key="id" as |virtualComponent| ~}}
    {{~unbound virtualComponent.upperBound~}}
    {{~#if virtualComponent.isOccludedContent ~}}
      {{{unbound virtualComponent.element}}}
    {{~else~}}
      {{~yield virtualComponent.content virtualComponent.index ~}}
    {{~/if~}}
    {{~unbound virtualComponent.lowerBound~}}
  {{~/each}}
  
  {{#if this.shouldYieldToInverse}}
    {{yield to="inverse"}}
  {{/if}}
  
  */
  {
    "id": "g/nau/et",
    "block": "{\"symbols\":[\"virtualComponent\",\"&else\",\"&default\"],\"statements\":[[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,0,[\"virtualComponents\"]]],null]],null]],[[\"key\"],[\"id\"]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[[32,1,[\"upperBound\"]]],null]],[6,[37,1],[[32,1,[\"isOccludedContent\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,[30,[36,0],[[32,1,[\"element\"]]],null]]],\"parameters\":[]},{\"statements\":[[18,3,[[32,1,[\"content\"]],[32,1,[\"index\"]]]]],\"parameters\":[]}]]],[1,[30,[36,0],[[32,1,[\"lowerBound\"]]],null]]],\"parameters\":[1]}]]],[2,\"\\n\"],[6,[37,1],[[32,0,[\"shouldYieldToInverse\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[18,2,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"unbound\",\"if\",\"-track-array\",\"each\"]}",
    "moduleName": "@html-next/vertical-collection/components/vertical-collection/template.hbs"
  });
  _exports.default = _default;
});