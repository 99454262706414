define("ember-rollbar-client/instance-initializers/rollbar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize(appInstance) {
    let fastbootService = appInstance.lookup('service:fastboot');
    let rollbarService = appInstance.lookup('service:rollbar');
    let oldOnError = Ember.onerror || function () {};
    Ember.onerror = function () {
      oldOnError(...arguments);
      let enabled = rollbarService.get('enabled');
      if (enabled) {
        rollbarService.error(...arguments);
      }
      if (!enabled || Ember.testing) {
        if (!fastbootService || !fastbootService.get('isFastBoot')) {
          throw arguments.length <= 0 ? undefined : arguments[0];
        }
      }
    };
  }
  var _default = {
    initialize
  };
  _exports.default = _default;
});