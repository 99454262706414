define("@appknox/ember-pace/initializers/ember-pace", ["exports", "pace-progressbar"], function (_exports, _paceProgressbar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize(application) {
    const config = application.resolveRegistration('config:environment');
    const paceConfig = config.pace;
    _paceProgressbar.default.start(paceConfig);
  }
  var _default = {
    initialize
  };
  _exports.default = _default;
});