define("ember-table/components/ember-table-simple-checkbox", ["exports", "ember-table/-private/utils/default-to"], function (_exports, _defaultTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: 'input',
    attributeBindings: ['ariaLabel:aria-label', 'checked', 'disabled', 'indeterminate', 'type', 'value', 'dataTestSelectRow:data-test-select-row', 'dataTestCollapseRow:data-test-collapse-row'],
    ariaLabel: undefined,
    checked: (0, _defaultTo.default)(false),
    disabled: (0, _defaultTo.default)(false),
    indeterminate: (0, _defaultTo.default)(false),
    onChange: null,
    onClick: null,
    type: 'checkbox',
    value: null,
    click(event) {
      var _this$onClick;
      (_this$onClick = this.onClick) === null || _this$onClick === void 0 ? void 0 : _this$onClick.call(this, event);
    },
    change(event) {
      var _this$onChange;
      let checked = this.element.checked;
      let indeterminate = this.element.indeterminate;
      let value = this.get('value');

      // Checked and indeterminate state have been changed, but that's not DDAU!
      // Reset the change, send the action and wait for it to be changed manually
      this.element.checked = this.get('checked');
      this.element.indeterminate = this.get('indeterminate');
      (_this$onChange = this.onChange) === null || _this$onChange === void 0 ? void 0 : _this$onChange.call(this, checked, {
        value,
        indeterminate
      }, event);
    }
  });
  _exports.default = _default;
});