define("@babel/runtime/helpers/esm/wrapRegExp", ["exports", "@babel/runtime/helpers/esm/typeof", "@babel/runtime/helpers/esm/wrapNativeSuper", "@babel/runtime/helpers/esm/inherits"], function (_exports, _typeof2, _wrapNativeSuper, _inherits) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _wrapRegExp;
  function _wrapRegExp(re, groups) {
    _exports.default = _wrapRegExp = function _wrapRegExp(re, groups) {
      return new BabelRegExp(re, undefined, groups);
    };
    var _RegExp = (0, _wrapNativeSuper.default)(RegExp);
    var _super = RegExp.prototype;
    var _groups = new WeakMap();
    function BabelRegExp(re, flags, groups) {
      var _this = _RegExp.call(this, re, flags);
      _groups.set(_this, groups || _groups.get(re));
      return _this;
    }
    (0, _inherits.default)(BabelRegExp, _RegExp);
    BabelRegExp.prototype.exec = function (str) {
      var result = _super.exec.call(this, str);
      if (result) result.groups = buildGroups(result, this);
      return result;
    };
    BabelRegExp.prototype[Symbol.replace] = function (str, substitution) {
      if (typeof substitution === "string") {
        var groups = _groups.get(this);
        return _super[Symbol.replace].call(this, str, substitution.replace(/\$<([^>]+)>/g, function (_, name) {
          return "$" + groups[name];
        }));
      } else if (typeof substitution === "function") {
        var _this = this;
        return _super[Symbol.replace].call(this, str, function () {
          var args = [];
          args.push.apply(args, arguments);
          if ((0, _typeof2.default)(args[args.length - 1]) !== "object") {
            args.push(buildGroups(args, _this));
          }
          return substitution.apply(this, args);
        });
      } else {
        return _super[Symbol.replace].call(this, str, substitution);
      }
    };
    function buildGroups(result, re) {
      var g = _groups.get(re);
      return Object.keys(g).reduce(function (groups, name) {
        groups[name] = result[g[name]];
        return groups;
      }, Object.create(null));
    }
    return _wrapRegExp.apply(this, arguments);
  }
});