define("ember-page-title/helpers/page-title", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  /**
    `{{page-title}}` helper used to set the title of the current route context.
  
    @public
    @method page-title
   */
  let PageTitle = (_dec = Ember.inject.service('page-title-list'), (_class = class PageTitle extends Ember.Helper {
    get tokenId() {
      return Ember.guidFor(this);
    }
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "tokens", _descriptor, this);
      this.tokens.push({
        id: this.tokenId
      });
    }
    compute(params, _hash) {
      let hash = Ember.assign({}, _hash, {
        id: this.tokenId,
        title: params.join('')
      });
      this.tokens.push(hash);
      this.tokens.scheduleTitleUpdate();
      return '';
    }
    willDestroy() {
      super.willDestroy();
      this.tokens.remove(this.tokenId);
      this.tokens.scheduleTitleUpdate();
    }
  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "tokens", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = PageTitle;
});