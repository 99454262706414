define("ember-awesome-macros/promise/then", ["exports", "ember-macro-helpers"], function (_exports, _emberMacroHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _emberMacroHelpers.lazyCurriedComputed)((getValue, promise, property) => {
    promise = getValue(promise);
    if (promise === undefined) {
      return Ember.RSVP.resolve();
    }
    return promise.then(x => {
      property = getValue(property);
      if (property !== undefined) {
        return Ember.get(x, property);
      }
    });
  });
  _exports.default = _default;
});