define("ember-modal-dialog/templates/components/modal-dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "30ZMdCuD",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,26],[[35,25]],[[\"wrapperClass\",\"wrapperClassNames\",\"overlayClass\",\"overlayClassNames\",\"containerClass\",\"containerClassNames\",\"hasOverlay\",\"translucentOverlay\",\"clickOutsideToClose\",\"destinationElementId\",\"overlayPosition\",\"tetherTarget\",\"legacyTarget\",\"attachment\",\"targetAttachment\",\"targetModifier\",\"targetOffset\",\"offset\",\"tetherClassPrefix\",\"constraints\",\"attachmentClass\",\"targetAttachmentClass\",\"stack\",\"value\",\"onClickOverlay\",\"onClose\"],[[35,24],[35,23],[35,22],[35,21],[35,20],[35,19],[35,18],[35,17],[35,16],[35,15],[35,14],[35,13],[35,12],[35,11],[35,10],[35,9],[35,8],[35,7],[35,6],[35,5],[35,4],[35,3],[35,2],[35,1],[30,[36,0],[[32,0],\"onClickOverlay\"],null],[30,[36,0],[[32,0],\"onClose\"],null]]],[[\"default\"],[{\"statements\":[[2,\"  \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"value\",\"stack\",\"targetAttachmentClass\",\"attachmentClass\",\"constraints\",\"tetherClassPrefix\",\"offset\",\"targetOffset\",\"targetModifier\",\"targetAttachment\",\"attachment\",\"target\",\"tetherTarget\",\"overlayPosition\",\"destinationElementId\",\"clickOutsideToClose\",\"translucentOverlay\",\"hasOverlay\",\"containerClassNames\",\"containerClass\",\"overlayClassNames\",\"overlayClass\",\"wrapperClassNames\",\"wrapperClass\",\"modalDialogComponentName\",\"component\"]}",
    "moduleName": "ember-modal-dialog/templates/components/modal-dialog.hbs"
  });
  _exports.default = _default;
});